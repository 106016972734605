'use client';

import {
    ButtonVariant,
    CartLineImage,
    CartLinePrice,
    CartLineProductTitle,
    EditIcon,
} from '@lib/machine-parts/storefront/ui';
import { Link } from '@lib/machine-parts/storefront/ui/nextjs';
import { CartLineQuantity, CartLineQuantityAdjustButton, useCartLine } from '@shopify/hydrogen-react';

import { useTranslation } from '@lib/machine-parts/storefront/utils';
import { useCartUI } from '@lib/machine-parts/storefront/checkout/data-access';

// import { useCheckout } from '../../../providers/CheckoutProvider.client';

export function LineInCart() {
    const { merchandise } = useCartLine();
    const { closeCart } = useCartUI();
    const { t } = useTranslation();
    // const { checkout, removeLineItems } = useCheckout();

    if (!merchandise?.id || !merchandise.product?.handle) return null;

    // const checkoutLineItem = checkout?.lineItems.nodes.find((lineItem) => lineItem.variant?.id === merchandise.id);
    // const handleRemove = () => {
    //     if (checkoutLineItem) {
    //         removeLineItems([checkoutLineItem.id]);
    //     }
    // };

    return (
        <div role="row" className="flex py-7 border-b border-brand-black text-brand-black" data-testid="cart-item">
            <div role="cell" className="flex-shrink-0 mr-7 flex items-center">
                <Link
                    href={`/products/${merchandise.product.handle}`}
                    mixpanel={{ eventName: `Cart Product Image To ${merchandise.product.handle} Button` }}
                    onClick={closeCart}
                    variant={ButtonVariant.None}
                >
                    <CartLineImage />
                </Link>
            </div>
            <div role="cell" className="flex flex-col w-full justify-between items-start flex-grow-1 mr-4 gap-1">
                <div className={`flex flex-col lg:flex-row justify-between w-full`}>
                    <Link
                        href={`/products/${merchandise.product.handle}`}
                        className="hover:underline"
                        mixpanel={{ eventName: `Cart Product Title To ${merchandise.product.handle} Button` }}
                        onClick={closeCart}
                        variant={ButtonVariant.None}
                        data-testid="cart-product-title"
                    >
                        <CartLineProductTitle />
                    </Link>
                    <CartLinePrice className="text-lg lg:text-2xl font-heading lg:font-bold" />
                </div>
                <ul className="gap-1 text-sm lg:text-base">{merchandise.product.handle}</ul>
                <div className={`flex w-full justify-between text-sm lg:text-base`}>
                    <div className={`flex items-center gap-2`}>
                        <span>{t('cart-amount', 'Amount:')}</span>
                        <CartLineQuantity as={`div`} />
                    </div>
                    <Link
                        href="/cart"
                        mixpanel={{ eventName: `Cart Edit ${merchandise.product.handle} Button` }}
                        className={`text-brand-brown flex items-center gap-2`}
                        onClick={closeCart}
                        variant={ButtonVariant.None}
                    >
                        <EditIcon className={`h-4`} />
                        <span className={``}>{t('cart-edit', 'Edit')}</span>
                    </Link>
                </div>
            </div>
            <div role="cell" className="flex flex-col justify-between items-end">
                <CartLineQuantityAdjustButton
                    adjust="remove"
                    aria-label={t('cart-remove', 'Remove from cart')}
                    className="disabled:pointer-events-all disabled:cursor-wait"
                    // onClick={handleRemove}
                    data-testid="remove-from-cart"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                </CartLineQuantityAdjustButton>
            </div>
        </div>
    );
}
