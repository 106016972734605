'use client';

import { ButtonVariant, CloseIcon, getButtonVariantClasses, SpinnerIcon } from '@lib/machine-parts/storefront/ui';
import { useCart } from '@shopify/hydrogen-react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Dialog } from '@headlessui/react';

import { CartFooter } from './CartFooter';
import { CartItems } from './CartItems';
import { useCartUI } from '@lib/machine-parts/storefront/checkout/data-access';
import { useTranslation } from '@lib/machine-parts/storefront/utils';

/**
 * A client component that contains the merchandise that a customer intends to purchase, and the estimated cost associated with the cart
 */
export default function Cart() {
    const { isCartOpen, closeCart } = useCartUI();
    const { totalQuantity, status } = useCart();

    const loading = status !== 'idle';

    return (
        <div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
                className={`z-10 fixed top-0 bottom-0 left-0 right-0 bg-black transition-opacity duration-400 ${
                    isCartOpen ? 'opacity-20' : 'opacity-0 pointer-events-none'
                }`}
                onClick={isCartOpen ? closeCart : undefined}
            />

            <Dialog open={isCartOpen} onClose={closeCart}>
                <Dialog.Overlay className="fixed z-10 left-0 right-0 bottom-0 top-0 bg-gray-500 opacity-75" />
                <div
                    className={`fixed flex flex-col md:block z-30 top-0 left-0 right-0 bottom-0 md:left-auto pt-14 lg:pt-32  bg-white w-full md:w-[470px] shadow-xl ${
                        totalQuantity === 0 ? 'overflow-hidden' : 'overflow-y-scroll'
                    }`}
                    data-testid="cart-dialog"
                >
                    {loading ? (
                        <div className="w-full flex items-center justify-center p-2 text-brand-brown">
                            <SpinnerIcon />
                        </div>
                    ) : (
                        <>
                            {totalQuantity === 0 ? (
                                <CartEmpty />
                            ) : (
                                <>
                                    <CartHeader />
                                    <CartItems />
                                    <CartFooter />
                                </>
                            )}
                        </>
                    )}
                </div>
            </Dialog>
        </div>
    );
}

function CartHeader() {
    const { closeCart } = useCartUI();
    const { t } = useTranslation();

    return (
        <div className={`h-14 lg:h-32 w-full md:w-[470px] p-8 flex justify-between items-center fixed top-0 bg-white`}>
            <span className={`text-3xl font-heading`}>{t('cart-my-cart', 'My Cart')}</span>
            <button onClick={closeCart} data-testid="close-cart">
                <CloseIcon className={`h-4`} />
            </button>
        </div>
    );
}

function CartEmpty() {
    const { closeCart } = useCartUI();
    const { t } = useTranslation();

    return (
        <div className="p-7 flex flex-col">
            <p className="mb-4 text-lg text-gray-500 text-center">{t('cart-empty', 'Your cart is empty')}</p>
            <button
                type="button"
                onClick={closeCart}
                className={getButtonVariantClasses({ variant: ButtonVariant.Primary })}
            >
                {t('cart-continue-shopping', 'Continue Shopping')}
            </button>
        </div>
    );
}
