'use client';

import { useTranslation } from 'react-i18next';
import { ButtonVariant } from '@lib/machine-parts/storefront/ui';
import { Link } from '@lib/machine-parts/storefront/ui/nextjs';
import { CartCost } from '@shopify/hydrogen-react';

import { useCartUI } from '@lib/machine-parts/storefront/checkout/data-access';

export function CartFooter() {
    const { closeCart } = useCartUI();
    const { t } = useTranslation();

    return (
        <footer className="bottom-0 sticky">
            <div className="relative bg-white text-brand-black p-7 gap-8 flex flex-col items-end ">
                <div className={`flex flex-col justify-between items-start w-full`}>
                    <div
                        className={`text-2xl lg:text-3xl font-heading flex justify-between items-center w-full uppercase`}
                    >
                        <span>{t('cart-subtotal', 'Subtotal')}</span>
                        <span role="cell">
                            <CartCost amountType="subtotal" />
                        </span>
                    </div>
                    <div className={`text-xs`}>{t('cart-taxes-info', 'Taxes and shipping calculated at checkout')}</div>
                </div>
                <Link
                    href="/cart"
                    onClick={closeCart}
                    mixpanel={{
                        eventName: `Go To Cart - Cart View Basket`,
                    }}
                    icon
                    data-testid="go-to-cart"
                >
                    <span>{t('cart-client-check', 'Check your cart')}</span>
                </Link>
                <div className={`text-right text-xs`}>
                    {t('cart-tc-notice', 'When you click on ‘checkout’ you automatically accept our ')}
                    <Link
                        href="/terms-and-conditions"
                        variant={ButtonVariant.None}
                        mixpanel={{ eventName: `Cart Terms and Conditions Button` }}
                        className={`underline`}
                        onClick={closeCart}
                    >
                        terms & conditions
                    </Link>
                </div>
            </div>
        </footer>
    );
}
